import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import styled, { css } from 'styled-components'
import StandardHero from '../components/heros/standard-hero'
import Layout from '../components/layout'
import Navigation from '../components/navigation'
import FeatureIconCard from '../components/cards/icon-feature-card'
import CheckListItem from '../components/atoms/check-list-item'

class SignupIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const tips = get(this, 'props.data.allContentfulTip.edges')

    return (
      <Layout location={this.props.location} >
        <Helmet title={siteTitle} />
        <Navigation />
        <StandardHero 
          heroHeading="Oh no the page you’re looking for cannot be found!" 
          heroParagraph="Sorry, this pages just isn't a page yet! However it might be in the future! If you have an idea, email me at callum@uiuxtips.com" 
          heroImage=""
          classes="no-padding-bottom"
        ></StandardHero>
      </Layout>
    )
  }
}

export default SignupIndex

export const pageNotFoundQuery = graphql`
  query pageNotFoundQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`

// Styles

const BlogList = styled.div`
  list-style: none;
`

const HeroEmojiSpan = styled.span`
  padding: 0 0px;
`

const SectionHeadline = styled.h2 `
  margin-bottom: 48px;
`

const TipCount = styled.div `
  color: #7A89FF;
  display: inline-block;
`

const YouTubeVideo = styled.iframe `
  border-radius: 8px;
  width: 100%;
`

const FeatureList = styled.ul `
  display: flex;
  flex-wrap: wrap;
`